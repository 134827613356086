<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/myFolder' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ currFolder.type === 1 ? '智能拍摄集' : '诊断规则集' }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-header>

    <el-container style="height: calc(100% - 40px); background: #f4f4f4">
      <el-container style="box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2); border-radius: 8px; background: white">
        <div style="padding: 20px 20px 0 20px">
          <div style="float: left">
            <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
              <el-form-item prop="name">
                <el-input v-model="dataForm.name" placeholder="文件名称" clearable></el-input>
              </el-form-item>
              <el-form-item prop="dateTime">
                <el-date-picker
                  v-model="dataForm.dateTime"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="→"
                  start-placeholder="选择开始日期"
                  end-placeholder="选择结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button @click="getDataList(true)" type="primary">查询</el-button>
                <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div style="float: right">
            <el-form :inline="true">
              <el-form-item>
                <el-button type="text" style="padding: 0 0 0 9px">
                  <el-upload
                    :show-file-list="false"
                    :on-change="handleChange"
                    action=""
                    multiple
                    :accept="currFolder.type === 1 ? '.xls, .xlsx' : '.zip, .rar, .tar, .7z'"
                    :auto-upload="false"
                  >
                    <el-button type="primary" slot="trigger"
                      ><el-image :src="require(`@/assets/img/icon_upload_def.png`)" fit="contain" style="vertical-align: middle"></el-image>
                      上传</el-button
                    >
                  </el-upload>
                </el-button>
                <el-button @click="toDown()" type="primary" :disabled="btnDisabled">
                  <el-image :src="require(`@/assets/img/icon_download_def.png`)" fit="contain" style="vertical-align: middle"></el-image>
                  下载</el-button
                >
                <el-button @click="toDelete()" type="danger" :disabled="btnDisabled"><i class="el-icon-delete"></i> 删除</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-main style="padding: 0 20px 20px 20px">
          <div style="height: 1px; background: #d4d6d9; opacity: 0.5"></div>
          <div class="main">
            <div v-if="dataList.length" v-infinite-scroll="getDataList" :infinite-scroll-distance="1">
              <div
                v-for="(item, index) in dataList"
                :key="index"
                class="inner-img"
                :style="fileStyle(index)"
                @click="imgClick(item, index)"
                @mouseenter="mouseenter(item)"
                @mouseleave="mouseleave(item)"
              >
                <el-image
                  class="img-item"
                  :src="require(`@/assets/img/${currFolder.type === 1 ? 'icon_excel_def' : 'icon_zip_def'}.png`)"
                  fit="contain"
                >
                </el-image>

                <div class="img-title">
                  <el-tooltip effect="dark" :content="item.name" placement="bottom-start" :visible-arrow="false">
                    <span> {{ item.name }} </span>
                  </el-tooltip>
                </div>
                <div class="img-check" v-show="item.isShow">
                  <el-checkbox v-model="checkedList[index]"></el-checkbox>
                </div>
                <el-popover trigger="click" :ref="`popover-${index}`" placement="right">
                  <div @click="toDown(item)" class="popover-btn">下载</div>
                  <div @click="toRename(item)" class="popover-btn">重命名</div>
                  <div @click="toDelete(item)" class="popover-btn">删除</div>
                  <div class="more" v-show="item.isShow" v-bind:title="'更多操作'" slot="reference">
                    <i class="el-icon-more"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <div v-else style="height: 100%; text-align: center">
              <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
              <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
            </div>
          </div>
          <el-dialog :visible.sync="dialogVisible" :show-close="false" :fullscreen="true" custom-class="shoot-rule-upload-dialog">
            <div @dragleave="dragleave" style="width: 100%; height: 100%">
              <el-upload :show-file-list="false" :on-change="handleChange" drag action="" multiple :auto-upload="false">
                <el-image :src="require(`@/assets/img/drag-upload_sel.png`)" style="height: 100%; width: 100%" fit="cover"> </el-image>
              </el-upload>
            </div>
          </el-dialog>
          <el-dialog title="重命名" :visible.sync="reNamedialogVisible" :close-on-click-modal="false">
            <el-form :model="reForm" ref="reForm" :rules="reRule" @keyup.enter.native="reName" label-width="40px">
              <el-form-item label="名称" prop="name">
                <el-input v-model="reForm.name" :placeholder="reForm.placeholder">
                  <template slot="append">{{ reForm.suffix }}</template>
                </el-input>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="reNamedialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="reName">确 定</el-button>
            </div>
          </el-dialog>
        </el-main>
        <el-footer style="height: 0"> </el-footer>
      </el-container>
      <el-aside style="height: 100%; width: 20px; background: #f4f4f4"></el-aside>
      <el-aside width="323px" class="info-aside">
        <div style="font-size: 16px; font-family: PingFangSC-Medium, PingFang SC; font-weight: bold; color: #3a3b3e; margin: 24px">详细信息</div>
        <el-descriptions :column="1" :colon="false" labelClassName="detail-label" v-if="showFlag" style="word-break: break-all">
          <el-descriptions-item label="名称">{{ currentFile.name }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ currentFile.createTime }}</el-descriptions-item>
          <el-descriptions-item label="大小">{{ currentFile.fileSize }}</el-descriptions-item>
        </el-descriptions>
        <div v-else style="height: calc(100% - 256px); padding: 0 24px">
          <img
            :src="require(`@/assets/img/${currFolder.type === 1 ? 'excel_sample_detailed-info' : 'zip_sample_detailed-info'}.png`)"
            width="96%"
            alt=""
          />
          <div style="font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #4d4e52; text-align: center; margin-top: 20px">
            选择以展示详细信息
          </div>
        </div>
      </el-aside>
    </el-container>
  </el-container>
</template>

<script>
import { changeToThousand } from '@/utils'
// import axios from 'axios'
// import Vue from 'vue'
export default {
  name: 'ShootAndRule',
  data() {
    var validateName = (rule, value, callback) => {
      if (!/\S/.test(value)) {
        callback(new Error('名称不能为空'))
      } else if (value.length > 255) {
        callback(new Error('名称不能超过255个字符'))
      } else if (value[0] === ' ') {
        callback(new Error('名称不能以空格开头'))
      } else if (/[\\/:*?"<>|]/.test(value)) {
        callback(new Error('名称不能包含\\ / : * ? " < > |'))
      } else {
        callback()
      }
    }
    return {
      dataForm: {
        name: '',
        dateTime: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      dataList: [],
      checkedList: [],
      currentIndex: undefined,
      currentFile: {},
      dataListSelectIds: [],
      pageIndex: 1,
      pageSize: 112,
      totalPage: 1,
      currFolder: {},
      showFlag: false,
      dialogVisible: false,
      reNamedialogVisible: false,
      reForm: {
        name: '',
        suffix: '',
        placeholder: ''
      },
      reRule: {
        name: [{ validator: validateName, trigger: 'blur' }]
      }
    }
  },
  computed: {
    btnDisabled() {
      return this.dataListSelectIds.length === 0
    },
    fileStyle() {
      return (index) => {
        return this.currentIndex === index ? 'box-shadow: 0px 4px 10px 0px rgba(168, 171, 179, 0.29);border-radius: 8px;' : ''
      }
    },
    isRefresh() {
      return this.$store.state.refreshFlag
    }
  },
  watch: {
    checkedList(val) {
      this.dataListSelectIds = []
      for (let i = 0; i < val.length; i++) {
        if (val[i]) {
          this.dataListSelectIds.push(this.dataList[i].id)
        }
      }
    },
    isRefresh() {
      if (this.pageIndex === 1) {
        this.getDataList(true)
      }
    }
  },
  created() {
    // 当前文件夹
    this.currFolder = JSON.parse(sessionStorage.currFolder || '{}')
    this.getDataList(true)
  },
  methods: {
    dragenter() {
      this.dialogVisible = true
    },
    dragleave() {
      this.dialogVisible = false
    },
    handleChange(file) {
      this.dialogVisible = false
      const { name, size, raw } = file

      setTimeout(() => {
        // 限制excel
        if (this.currFolder.type === 1 && (!name || ['xls', 'xlsx'].indexOf(name.split('.')[name.split('.').length - 1]) === -1)) {
          this.$message({
            message: `只支持上传Excel文件至智能拍摄集，${name}不符合条件`,
            type: 'error',
            duration: 3000
          })
          return false
        } else if (this.currFolder.type === 2 && (!name || ['zip', 'rar', 'tar', '7z'].indexOf(name.split('.')[name.split('.').length - 1]) === -1)) {
          this.$message({
            message: `只支持上传压缩文件至诊断规则集，${name}不符合条件`,
            type: 'error',
            duration: 3000
          })
          return false
        }

        // 校验名称
        if (name.length > 255) {
          this.$message({
            message: `文件名称不能超过255个字符，${name}不符合条件`,
            type: 'error',
            duration: 3000
          })
          return false
        } else if (name[0] === ' ') {
          this.$message({
            message: `文件名称不能以空格开头，${name}不符合条件`,
            type: 'error',
            duration: 3000
          })
          return false
        } else if (/[\\/:*?"<>|]/.test(name)) {
          this.$message({
            message: `文件名称不能包含\\ / : * ? " < > |，${name}不符合条件`,
            type: 'error',
            duration: 3000
          })
          return false
        }

        // 单个文件限制500M
        if (size > 500 * 1024 * 1024) {
          this.$message({
            message: '单个文件大小最大支持500M，' + name + '超出限制，请调整后重新上传',
            type: 'error',
            duration: 3000
          })
          return false
        }

        // 限制相同上传任务
        const unsuccessFileList = this.$store.state.unsuccessFileList || []
        if (unsuccessFileList.indexOf(name) !== -1) {
          this.$message({
            message: name + '，已存在上传任务，不必重复上传',
            type: 'error',
            duration: 3000
          })
          return false
        }

        // 判断剩余存储
        this.$http({
          url: this.$http.adornUrl('/api/v1/files/userSpace'),
          method: 'GET',
          params: this.$http.adornParams({
            uploadFileSize: Math.ceil(size / 1024)
          })
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data
              if (data.canUpload) {
                const uploadObj = {
                  // 文件
                  file: raw,
                  // 业务参数
                  params: {
                    type: this.currFolder.type,
                    path: this.currFolder.type === 1 ? '首页/智能拍摄集' : '首页/诊断规则集'
                  }
                  // 其他参数
                }

                this.$store.commit('addUpload', uploadObj)
              } else {
                this.$message({
                  message: '个人云存储空间容量不足，请清理后重新上传',
                  type: 'error',
                  duration: 3000
                })
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(() => {})
      }, 0)
    },
    // 鼠标移入
    mouseenter(item) {
      this.$set(item, 'isShow', true)
    },
    // 鼠标移出
    mouseleave(item) {
      if (this.dataListSelectIds.indexOf(item.id) === -1) {
        this.$set(item, 'isShow', false)
      }
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1)
    },
    // 下载
    toDown(item) {
      if (item) {
        // 单个下载
        window.open((process.env.NODE_ENV === 'development' ? item.url : location.origin + '/' + item.path) + '?filename=' + item.name, '_blank')
        // window.location.href = (process.env.NODE_ENV === 'development' ? item.url : location.origin + '/' + item.path) + '?filename=' + item.name
      } else {
        if (this.dataListSelectIds.length > 1) {
          // 多个下载
          // 调用浏览器
          window.open(this.$http.adornUrl('/api/v1/files/special/download') + '?ids=' + this.dataListSelectIds.toString(), '_blank')
          // window.location.href = this.$http.adornUrl('/api/v1/files/special/download') + '?ids=' + this.dataListSelectIds.toString()
          // axios({
          //   timeout: 1000 * 60 * 5,
          //   url: this.$http.adornUrl('/api/v1/files/special/download'),
          //   method: 'post',
          //   params: {
          //     ids: this.dataListSelectIds.toString()
          //   },
          //   responseType: 'blob',
          //   headers: {
          //     Authorization: Vue.cookie.get('token'),
          //     'x-app-name': 'web'
          //   },
          //   onDownloadProgress(progress) {
          //     console.log('progress', progress)
          //   }
          // }).then(res => {
          //   console.log('res', res)
          //   const blob = res.data
          //   const fileName = this.currFolder.name + '.zip'

          //   if ('download' in document.createElement('a')) {
          //     // 非IE下载
          //     const elink = document.createElement('a')
          //     elink.download = fileName
          //     elink.style.display = 'none'
          //     elink.href = URL.createObjectURL(blob)
          //     document.body.appendChild(elink)
          //     elink.click()
          //     URL.revokeObjectURL(elink.href) // 释放URL 对象
          //     document.body.removeChild(elink)
          //   } else {
          //     // IE10+下载
          //     navigator.msSaveBlob(blob, fileName)
          //   }
          // })
        } else {
          // 单个下载
          window.open(
            (process.env.NODE_ENV === 'development' ? this.currentFile.url : location.origin + '/' + this.currentFile.path) +
              '?filename=' +
              this.currentFile.name,
            '_blank'
          )
          // window.location.href =
          //   (process.env.NODE_ENV === 'development' ? this.currentFile.url : location.origin + '/' + this.currentFile.path) +
          //   '?filename=' +
          //   this.currentFile.name
        }
      }
    },
    // 删除
    toDelete(item) {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">确定删除所选文件吗？</div> 
          <div class="tmc_confirm_content">站内文件也占用容量空间，删除的文件可在10天内通过回收站还原</div>
        </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl('/api/v1/files/special/delete'),
            method: 'delete',
            data: this.$http.adornData({
              ids: item ? [item.id] : this.dataListSelectIds
            })
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList(true)
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 重命名
    toRename(item) {
      const { id, name } = item
      this.reForm = {
        id,
        name: name.substring(0, name.lastIndexOf('.')),
        suffix: name.substring(name.lastIndexOf('.')),
        placeholder: name.substring(0, name.lastIndexOf('.'))
      }
      this.reNamedialogVisible = true
    },
    reName() {
      this.$refs.reForm.validate((valid) => {
        if (valid) {
          const { id, name, suffix } = this.reForm
          this.$http({
            url: this.$http.adornUrl('/api/v1/files/special/rename'),
            method: 'post',
            data: this.$http.adornData({
              id,
              name: name.trim() + suffix
            })
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.reNamedialogVisible = false
                  this.getDataList(true)
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 单击
    imgClick(item, index) {
      this.currentIndex = index
      this.currentFile = item
      // 文件大小按千位分隔符格式显示
      this.$set(this.currentFile, 'fileSize', changeToThousand(item.size) + 'KB')
      this.showFlag = true
    },
    getDataList(flag) {
      for (let i = 0; i < this.checkedList.length; i++) {
        this.$set(this.checkedList, i, false)
      }
      if (flag) {
        this.pageIndex = 1
      } else {
        if (this.pageIndex < this.totalPage) {
          this.pageIndex++
        } else {
          return
        }
      }
      // 文件
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/special/list'),
        method: 'get',
        params: this.$http.adornParams({
          type: this.currFolder.type,
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          name: this.dataForm.name,
          beginUploadTime: this.dataForm.dateTime ? this.dataForm.dateTime[0] : null,
          endUploadTime: this.dataForm.dateTime ? this.dataForm.dateTime[1] : null
        })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data || {}
          const list = data.list || []
          if (flag) {
            this.dataList = list
            // 重置数据
            this.currentIndex = undefined
            this.currentFile = {}
            this.showFlag = false
          } else {
            this.dataList.push(...list)
          }
          this.totalPage = data.pages
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
}
.info-aside {
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
  border-radius: 8px;
  background: white;
  ::v-deep .el-input__inner {
    height: 40px;
  }
  .form-class {
    margin-left: 15px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
.el-divider__text {
  font-weight: bold;
}
.el-divider--horizontal {
  background: #02a7f0;
}
::v-deep .el-form-item__content {
  margin-right: 20px;
  box-sizing: border-box;
}
.inner-img {
  width: 112px;
  height: 132px;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 10px 0 14px;
  position: relative;
  cursor: pointer;
  .img-item {
    margin: 16px 29px 15px 29px;
  }
  .img-title {
    text-align: center;
    margin: 0 0 8px 0;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .img-check {
    position: absolute;
    top: -2px;
    left: 4px;
  }
  .more {
    position: absolute;
    top: -2px;
    right: 4px;
  }
  .more:hover {
    color: #a8a9ee;
  }
  .more:active {
    color: #5053dd;
  }
}
.popover-btn {
  cursor: pointer;
}
.popover-btn:hover {
  color: #a8a9ee;
}
.popover-btn:active {
  color: #5053dd;
}
::v-deep .el-input-number {
  width: 100%;
}
::v-deep .el-upload {
  width: 100%;
  .el-upload-dragger {
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
}
</style>

<style lang="scss">
.shoot-rule-upload-dialog {
  background-color: rgba(65, 65, 65, 0.5);
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    height: 100%;
    div {
      height: 100%;
      overflow: hidden;
    }
    .el-upload-dragger {
      border: none;
      border-radius: 0;
    }
  }
}
</style>
